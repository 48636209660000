@import "./variables.scss";
$bg-img: url('assets/images/bg_circle.svg');

* {
  box-sizing: border-box;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;    
  background-image: $bg-img;
  background-position: fixed;
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  z-index: 1;
}

body {
  margin: 0;
  font-family: sans-serif;
  font-family: Arial, Helvetica, sans-serif;
  font-family: 'Inter', BlinkMacSystemFont, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.ms-Grid {
  max-width: calc(100vw - 15px);
}

.responsiv-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rtl>p,
.rtl>div,
.fui-DataGridCell>.rtl {
  direction: rtl;
}

.mobile-table {
  display: none;
}

.desktop-table {
  display: block;
}

.mobile-only {
  display: inherit;
}

@media only screen and (max-width: 768px) {
  .mobile-table {
    display: block;
  }

  .desktop-table {
    display: none;
  }
}

/* Min Width for Desktop */
@media only screen and (max-width: 550px) {
  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: inherit;
  }
}

@media only screen and (min-width: 550px) {
  .desktop-only {
    display: inherit;
  }

  .mobile-only {
    display: none;
  }
}

/* MinWidth for Mobile */
@media only screen and (max-width: 320px) {}
