:root {
    /* common */
    --brand-color: #6264A7;
    --button-color: #6264A7;
    --button-text-color: #fff;
    --button-hover-color: rgb(88, 90, 150);
    --button-hover-text-color: #fff;
    --button-active-color: rgb(70, 71, 117);
    --button-active-text-color: #fff;
    --button-border: 1px solid hsla(0, 0%, 100%, .04);
    --button-shadow: rgb(0 0 0 / 25%) 0px 0.2rem 0.4rem -0.075rem;
    --button2-color: #fff;
    --button2-text-color: rgb(37, 36, 35);
    --button2-hover-color: rgb(237, 235, 233);
    --button2-active-color: rgb(225, 223, 221);
    --button2-border: 1px solid rgb(225, 223, 221);
    --button2-shadow: rgb(0 0 0 / 10%) 0px 0.2rem 0.4rem -0.075rem;
    --button-disabled-color: rgb(237, 235, 233);
    --button-disabled-text-color: rgb(200, 198, 196);
    --input-background-color: rgb(243, 242, 241);
    --input-border-color: transparent;
    --input-border-width: 0 0 0.1429rem 0;
    --input-focus-border-color: transparent;
    --input-focus-border-bottom-color: #6264A7;
    --table-color: transparent;
    --table-border: 1px solid rgb(237, 235, 233);
    --border-color: rgb(237, 235, 233);

    /* light theme */
    --font-color: #252423;
    --background-color: #fff;
    --link-color: #6264A7;
    --border-color: #E1DFDD;
    --warning-color: #C4314B;
    --modal-background-color: #fafafa;
    --second-layer-background-color: #fafafa;
    --tcf-detailsmodal-tagging-backgound-color: #fafafa;

    .dark {
        --font-color: #fff;
        --background-color: #transparent;
        --link-color: #A6A7DC;
        --border-color: #605E5C;
        --warning-color: #F9526B;
        --modal-background-color: #121212;
        --second-layer-background-color: #121212;
        --tcf-detailsmodal-tagging-backgound-color: #121212;
    }

    .contrast{
        --brand-color: #ffff01;
        --font-color: #fff;
        --link-color: #ffff01;
        --background-color: transparent;
        --border-color: #fff;
        --button-color: transparent;
        --button-text-color: #fff;
        --button-hover-color: #ffff01;
        --button-hover-text-color: #000;
        --button-active-color: #1aebff;
        --button-active-text-color: #000;
        --button-border: .2rem solid #fff;
        --input-background-color: transparent;
        --input-border-color: #fff;
        --input-border-width: 1px;
        --input-focus-border-color: #1aebff;
        --input-focus-border-bottom-color: #1aebff;
        --warning-color: #ffff01;
        --modal-background-color: #000;
        --second-layer-background-color: #000;
        --tcf-detailsmodal-tagging-backgound-color: #000;
    }
}